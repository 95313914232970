<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<div class="title">{{ title }}</div>
			</div>
			<div style="margin: 0 50px">
				<baseTable
					ref="baseTable"
					@request="getTableData"
					:queryItem="queryItem"
					:columns="tableCols"
					:queryValues="defaultValues"
					showOrder
				/>
			</div>
		</div>
	</div>
</template>
<script>
import baseTable from '@/views/components/components-base-table';
import { setUrlParams } from '../../../utils/util';
export default {
	components: { baseTable },
	computed: {},
	data() {
		// const validateStaff = ({ message }, value, callback) => {
		// 	if (!this.selectedStaffs.length) {
		// 		callback(new Error(message || 'error'));
		// 	} else {
		// 		callback();
		// 	}
		// };
		return {
			title: '',
			subLoading: false,
			defaultValues: { type: '1' },
			tableCols: [
				{ label: '系数', key: 'ratio' },
				{ label: '财年', key: 'year' },
				{ label: '姓名', key: 'staffName' },
				{
					label: '操作',
					key: 'cz',
					render: (row) => (
						<div>
							<el-button type="text" onclick={() => this.handleEdit(row)}>
								编辑
							</el-button>
							<el-button type="text" style="color:rgb(245, 108, 108)" onclick={() => this.handleDel(row)}>
								删除
							</el-button>
						</div>
					)
				}
			],
			queryItem: [
				{
					key: 'type',
					label: '调整系数',
					type: 'select',
					clearable: false,
					// span: 12,
					options: [
						{ dictId: '1', dictName: '公司年度考核系数' },
						{ dictId: '2', dictName: '个人年度考核系数' }
					]
				}
			]
		};
	},
	mounted() {
		this.title = '调整记录';
	},
	methods: {
		// table
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/stock/ratio/listByPage', params));
		},
		handleEdit(row) {
			this.$router.push({ path: 'coefficientAdjustment', query: { params: setUrlParams(row) } });
		},
		// 删除
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/stock/ratio/del', {
						request: { id: row.id },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						}
					});
			});
		}
	}
};
</script>
<style lang="scss">
.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		// background-color: #f6f7fb;

		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			margin: 20px;

			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
		}
	}
}

.formBox {
	padding: 40px 20px;
	background: #fff;
	// margin: 20px;
	.el-form-item__content {
		.el-tag {
			margin: 0 10px 10px 0;
		}
		.el-range-separator {
			width: 20px;
		}
	}
}

.form {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	.el-form-item {
		width: 40%;

		.el-form-item__content {
			width: 60%;

			.el-select {
				width: 100%;
			}
		}
	}

	.form_footer_item {
		width: 100%;

		.el-form-item__content {
			width: 100%;

			.el-select {
				width: 100%;
			}
		}
	}
}

.form_footer {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	.footer_btn {
		cursor: pointer;
		font-size: 14px;
		width: 140px;
		line-height: 40px;
		text-align: center;
		height: 40px;
		border-radius: 6px;
		color: #fff;
	}

	.cancle_btn {
		margin-right: 40px;
		color: #c5cdda;
		border: 1px solid #dcdfe6;
	}

	.enter_btn {
		background-color: #409eff;
	}
}
</style>
